import commonHelper from '@/app/utility/common.helper.utility';
import generateBill from '../generateBill';
export default {
  name: 'viewInvoiceDtls',
  props: ['billRowData'],
  components: { generateBill },
  watch: {
    currentPage: function() {
      this.getViewInvDtlsData();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getViewInvDtlsData();
    }
  },
  data() {
    return {
      loader: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1,
      legalEntity: null,
      period: null,
      state: null,
      costCenter: null,
      viewInvDtlsData: [],
      viewInvDtlsFields: [
        {
          key: 'selectBox',
          label: 'Select',
          stickyColumn: true,
          variant: 'info',
          class: 'col-checkbox col-sm-1'
        },
        {
          key: 'party_name',
          label: 'Customer Name',
          class: 'text-center'
        },
        {
          key: 'customer_account_num',
          label: 'Account Number',
          class: 'text-center'
        },
        {
          key: 'party_site',
          label: 'Customer Site Name',
          class: 'text-center'
        },
        {
          key: 'fp_gstn',
          label: 'FP GST',
          class: 'text-center'
        },
        {
          key: 'party_address',
          label: 'Address',
          class: 'text-center'
        },
        {
          key: 'tp_gstn',
          label: 'TP GST',
          class: 'text-center'
        },
        {
          key: 'invoice_num',
          label: 'Invoice Number',
          class: 'text-center'
        },
        {
          key: 'invoice_amount',
          label: 'Invoice Amount',
          class: 'text-center'
        },
        {
          key: 'invoice_date',
          label: 'Invoice Date',
          class: 'text-center'
        },
        {
          key: 'gl_date',
          label: 'GL Date',
          class: 'text-center'
        },
        {
          key: 'ora_trx_id',
          label: 'Oracle TRX Id',
          class: 'text-center'
        }
      ],
      accessButtons: {
        deleteAllow: false,
        downloadAllow: false,
        uploadAllow: false,
        editAllow: true,
        addAllow: true,
        printAllow: false,
        approvalAllow: false
      },
      rowData: null,
      showGenerateBillModal: false,
      billHdrId: null,
      selectedRow: [],
      selectedRecords: 0,
      selectAllCheckBox: false
    };
  },
  validations: {},
  mounted() {
    // this.unsubscribe = this.$store.subscribe((mutation, state) => {
    //   if (mutation.type === 'shared/setActionName') {
    //     const actionName = state.shared.actionName;
    //     if (actionName === 'update') {
    //       this.editMode = true;
    //     }
    //     if (actionName === 'save') {
    //       this.updateGenerateBillData();
    //     }
    //   }
    // });
    if (this.billRowData) {
      this.legalEntity = this.billRowData.le_id;
      this.period = this.billRowData.period_name;
      this.state = this.billRowData.state;
      this.costCenter = this.billRowData.cost_center;
      this.getViewInvDtlsData();
    }
  },
  methods: {
    getViewInvDtlsData() {
      // const billHdrIds = this.billRowData.bill_hdr_id
      //   ? this.billRowData.bill_hdr_id.map(elem => {
      //       return {
      //         bill_hdr_id: elem.bill_hdr_id
      //       };
      //     })
      //   : [];
      const payload = {
        page: this.currentPage - 1,
        limit: this.perPage,
        cost_hdr_id: this.billRowData.cost_hdr_id
      };
      this.loader = true;
      this.$store
        .dispatch('fcBilling/getViewInvDtlsData', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 201) {
            const result = resp.data.data.page;
            this.viewInvDtlsData = result;
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelected(item) {
      this.rowData = item;
      this.rowData.formName = 'viewInvoice';
      this.rowData.cost_hdr_id = this.billRowData.cost_hdr_id;
      this.showHideGenerateBillModal(true);
    },
    showHideGenerateBillModal(flag) {
      this.showGenerateBillModal = flag;
    },
    selectBoxChecked(flag, index, item, billHdrId) {
      this.viewInvDtlsData[index].selectBox = flag;
      if (flag === true) {
        this.selectedRow.push(item);
        this.selectedRecords = this.selectedRecords + 1;
      } else {
        this.selectedRow = this.selectedRow.filter(
          el => el.bill_hdr_id !== billHdrId
        );
        this.selectedRecords = this.selectedRecords - 1;
      }
    },
    selectAllBoxChecked(flag) {
      this.selectedRow = [];
      if (flag) {
        this.viewInvDtlsData = this.viewInvDtlsData.map((data, index) => {
          data.selectBox = this.selectAllCheckBox;
          if (this.selectAllCheckBox) {
            this.selectBoxChecked(data.selectBox, index);
          }
          return data;
        });
        this.selectedRow = this.viewInvDtlsData;
      } else {
        this.viewInvDtlsData = this.viewInvDtlsData.map((data, index) => {
          data.selectBox = this.selectAllCheckBox;
          if (this.selectAllCheckBox) {
            this.selectBoxChecked(data.selectBox, index);
          }
          return data;
        });
      }
    },
    postToOracleInvData() {
      const filteredSelectedData = this.selectedRow.filter(
        ele => ele.selectBox
      );
      if (filteredSelectedData.length) {
        const payloadItemDtls = filteredSelectedData.map(ele => {
          return {
            bill_hdr_id: ele.bill_hdr_id
          };
        });
        const payload = {
          bill_hdr_ids: payloadItemDtls
        };
        this.loader = true;
        this.$store
          .dispatch('fcBilling/postToOracleInvData', payload)
          .then(resp => {
            this.loader = false;
            if (resp.status === 201) {
              this.editMode = false;
              // this.$bvToast.toast(resp.data.message, {
              //   title: 'Alert',
              //   variant: 'success',
              //   solid: true
              // });
              if (resp.data.data.OutputParameters[0].RETURN_CODE === 'E') {
                this.$bvToast.toast(
                  resp.data.data.OutputParameters[0].RETURN_MESSAGE,
                  {
                    title: 'Alert',
                    variant: 'danger',
                    solid: true
                  }
                );
              } else {
                this.$bvToast.toast(resp.data.message, {
                  title: 'Alert',
                  variant: 'success',
                  solid: true
                });
              }
              this.getViewInvDtlsData();
            } else {
              this.$bvToast.toast(resp.response.data.message, {
                title: 'Alert',
                variant: 'danger',
                solid: true
              });
            }
          })
          .catch(() => {
            this.loader = false;
          });
      } else {
        this.$bvToast.toast('Please select invoices first.', {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
      }
    }
  }
  //   beforeDestroy() {
  //     this.unsubscribe();
  //   }
};
