import commonHelper from '@/app/utility/common.helper.utility';
import commonModalOracle from '../../../provisionsAdmin/commonModalOracle';
import DatePicker from 'vue2-datepicker';
import { required } from 'vuelidate/lib/validators';
export default {
  name: 'generateBill',
  props: ['billRowData'],
  components: { commonModalOracle, DatePicker },
  watch: {
    currentPage: function() {
      this.getGenerateBillData();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getGenerateBillData();
    }
  },
  data() {
    return {
      loader: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1,
      legalEntity: {
        text: null,
        value: null
      },
      period: null,
      state: null,
      costCenter: null,
      fpGst: null,
      customer: {
        text: null,
        value: null
      },
      accNum: null,
      customerSite: {
        text: null,
        value: null
      },
      gstNum: null,
      address: null,
      invNum: null,
      invDate: null,
      glDate: null,
      taxCatgory: {
        text: null,
        value: null
      },
      trxType: {
        text: null,
        value: null,
        code: null
      },
      initialGenerateBillData: [],
      generateBillData: [],
      generateBillFields: [
        {
          key: 'selectBox',
          label: 'Select',
          stickyColumn: true,
          variant: 'info',
          class: 'col-checkbox col-sm-1'
        },
        {
          key: 'dtl_id',
          label: 'Dtl Id',
          class: 'text-center'
        },
        {
          key: 'doc_sequence_value',
          label: 'Doc Sequence Value',
          class: 'text-center'
        },
        {
          key: 'invoice_num',
          label: 'Invoice Num',
          class: 'text-center'
        },
        {
          key: 'invoice_date',
          label: 'Invoice Date',
          class: 'text-center'
        },
        {
          key: 'debit',
          label: 'Debit',
          class: 'text-center'
        },
        {
          key: 'credit',
          label: 'Credit',
          class: 'text-center'
        },
        {
          key: 'net_amt',
          label: 'Net Amount',
          class: 'text-center'
        },
        {
          key: 'amount_already_billed',
          label: 'Amt. Already Billed',
          class: 'text-center'
        },
        {
          key: 'amount_not_to_be_billed',
          label: 'Amt. Not To Be Billed',
          class: 'text-center'
        },
        {
          key: 'remark',
          label: 'Remarks',
          class: 'text-center'
        },
        {
          key: 'amount_remaining',
          label: 'Remaining Amt.',
          class: 'text-center'
        },
        {
          key: 'amount_to_be_billed',
          label: 'Amt. To Be Billed',
          class: 'text-center'
        },
        {
          key: 'percentage',
          label: 'Percentage',
          class: 'text-center'
        },
        {
          key: 'additional_amount',
          label: 'Amount',
          class: 'text-center'
        },
        {
          key: 'tax',
          label: 'Tax Category',
          class: 'text-center'
        }
      ],
      editMode: false,
      modalType: null,
      requestPara: {},
      showCommonModal: false,
      vSetType: null,
      specIndex: null,
      postToOracleId: null,
      selectedRow: [],
      selectedRecords: 0,
      selectAllCheckBox: false
    };
  },
  validations: {
    customer: { text: { required } },
    customerSite: { text: { required } }
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'save') {
          this.updateGenerateBillData();
        }
      }
    });
    if (this.billRowData) {
      if (this.billRowData.formName === 'gridForm') {
        this.legalEntity = {
          text: this.billRowData.le_id,
          value: this.billRowData.le_id_code
        };
        this.fpGst = this.billRowData.fp_gst;
      } else {
        this.legalEntity = {
          text: this.billRowData.le_name,
          value: this.billRowData.le_id
        };
        this.fpGst = this.billRowData.fp_gstn;
      }
      this.period = this.billRowData.period_name;
      this.state = this.billRowData.state;
      this.costCenter = this.billRowData.cost_center;
      if (this.billRowData.formName === 'viewInvoice') {
        this.postToOracleId = this.billRowData.ora_trx_id;
        this.customer = {
          text: this.billRowData.party_name,
          value: this.billRowData.party_id
        };
        this.accNum = this.billRowData.customer_account_num;
        this.customerSite = {
          text: this.billRowData.party_site,
          value: this.billRowData.party_site_id
        };
        this.gstNum = this.billRowData.tp_gstn;
        this.address = this.billRowData.party_address;
        this.invNum = this.billRowData.invoice_num;
        this.invDate = this.billRowData.invoice_date;
        this.glDate = this.billRowData.gl_date;
        this.billHdrId = this.billRowData.bill_hdr_id;
        this.trxType.text = this.billRowData.trx_type;
        this.trxType.code = this.billRowData.trx_type;
      }
      this.getGenerateBillData();
    }
  },
  methods: {
    getGenerateBillData() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        cost_hdr_id: this.billRowData.cost_hdr_id,
        bill_hdr_id: this.billHdrId ? this.billHdrId : 0,
        ora_id_flag: this.billRowData.ora_trx_id ? 'Y' : 'N'
      };
      this.loader = true;
      this.$store
        .dispatch('fcBilling/getGenerateBillData', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.selectedRow = [];
            const result = resp.data.data.page.map(ele => {
              ele.net_amt = +ele.debit - +ele.credit;
              ele.amount_remaining =
                +ele.debit -
                +ele.credit -
                +ele.amount_already_billed -
                +ele.amount_not_to_be_billed;
              ele.amount_to_be_billed = ele.amount_remaining;
              ele.percentage = ele.percentage ? ele.percentage : 18;
              // ele.percentage = 18;
              ele.additional_amount =
                +ele.amount_to_be_billed +
                (+ele.amount_to_be_billed * +ele.percentage) / 100;
              if (ele.bill_dtl_id && !this.billRowData.ora_trx_id) {
                ele.selectBox = true;
                this.selectedRow.push(ele);
              }
              return ele;
            });
            // result.forEach(item => {
            //   if (item.bill_dtl_id) {
            //     item.selectBox = true;
            //     saveDtls.push(item);
            //   }
            // });
            this.generateBillData = result;
            // const modifyData = resp.data.data.page.map(ele => {
            //   ele.percentage = 18;
            //   return ele;
            // });
            // this.initialGenerateBillData = JSON.parse(JSON.stringify(result));
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    formattedAmount(amt) {
      return commonHelper.formatAmountWithoutCurrency(amt);
    },
    calculateRemainingAmt(item) {
      item.amount_to_be_billed = 0;
      if (item.amount_not_to_be_billed > item.net_amt) {
        item.amount_not_to_be_billed = 0;
        item.amount_remaining =
          +item.net_amt -
          +item.amount_already_billed -
          +item.amount_not_to_be_billed;
        this.$bvToast.toast(
          'Amount not to be billed should not exceed the net amount - amount already billed.',
          {
            title: 'Alert',
            variant: 'danger',
            solid: true
          }
        );
      } else {
        item.amount_remaining =
          +item.net_amt -
          +item.amount_already_billed -
          +item.amount_not_to_be_billed;
      }
    },
    validateAmtBilled(item) {
      if (item.amount_to_be_billed > item.amount_remaining) {
        item.amount_to_be_billed = 0;
        this.$bvToast.toast(
          'Amount to be billed should not exceed the remainig amount.',
          {
            title: 'Alert',
            variant: 'danger',
            solid: true
          }
        );
      } else {
        this.calAdditinalAmt(item);
      }
    },
    calAdditinalAmt(item) {
      if (item.amount_to_be_billed) {
        item.additional_amount =
          +item.amount_to_be_billed +
          (+item.amount_to_be_billed * +item.percentage) / 100;
      } else {
        item.percentage = 0;
        this.$bvToast.toast('Please enter Amount to be billed first', {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
      }
    },
    showHideCommonModal(flag, type, vSetType, index) {
      this.modalType = type;
      this.specIndex = index;
      this.requestPara.reqType = type === 'customer' ? 'customer' : 'sites';

      if (this.modalType === 'tax_category' || this.modalType === 'trx_type') {
        this.requestPara.ou_id = this.billRowData.ou_id;
        this.requestPara.vSetType = vSetType;
      }
      if (flag) {
        if (this.modalType === 'customer_site') {
          this.requestPara.customerId = this.customer.value;
          this.requestPara.ou_id = this.billRowData.ou_id;
        }
      }
      if (this.modalType === 'customer_site' && !this.customer.value) {
        this.showCommonModal = false;
        this.$bvToast.toast('Please Select Customer Name first.', {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
      } else {
        this.showCommonModal = flag;
      }
    },
    mapSelectedVsetData(item) {
      if (this.modalType === 'customer') {
        this.customer = {
          text: item.customer_name,
          value: item.customer_id
        };
        this.accNum = item.account_number;
        this.customerSite = {
          text: null,
          value: null
        };
        this.address = null;
        this.gstNum = null;
      } else if (this.modalType === 'customer_site') {
        this.customerSite.text = item.Customer_site_name;
        this.customerSite.value = item.Customer_site_id;
        this.address = item.address;
        this.gstNum = item.gst;
      } else if (this.modalType === 'tax_category') {
        if (item.vSetType === 'header') {
          this.taxCatgory = {
            text: item.tax_category_name,
            value: item.tax_category_id
          };
        } else {
          this.generateBillData[this.specIndex].tax = item.tax_category_name;
          this.generateBillData[this.specIndex].tax_cat_id =
            item.tax_category_id;
        }
      } else if (this.modalType === 'trx_type') {
        this.trxType = {
          text: item.name,
          value: item.cust_trx_type_id,
          code: item.type
        };
      }
      this.showHideCommonModal(false);
    },
    clearVsetValues(vsetCode, index) {
      if (vsetCode === 'customer') {
        this.customer = {
          text: null,
          value: null
        };
        this.accNum = null;
        this.customerSite = {
          text: null,
          value: null
        };
        this.gstNum = null;
        this.address = null;
      } else if (vsetCode === 'customerSite') {
        this.customerSite = {
          text: null,
          value: null
        };
        this.gstNum = null;
        this.address = null;
      } else if (vsetCode === 'taxCategory') {
        this.taxCatgory = {
          text: null,
          value: null
        };
      } else if (vsetCode === 'taxCat') {
        this.generateBillData[index].tax = null;
        this.generateBillData[index].tax_cat_id = null;
      } else if (vsetCode === 'trxType') {
        this.trxType = {
          text: null,
          value: null,
          code: null
        };
      }
    },
    updateGenerateBillData() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        // const itemDetails = this.generateBillData.map(elem => {
        //   return {
        //     dtl_id: elem.dtl_id,
        //     bill_amount: elem.amount_to_be_billed,
        //     amount_not_to_be_billed: elem.amount_not_to_be_billed,
        //     remark: elem.remark,
        //     tax_category: 2
        //   };
        // });
        const filteredSelectedData = this.selectedRow.filter(
          ele => ele.selectBox
        );
        if (filteredSelectedData.length) {
          const payloadItemDtls = filteredSelectedData.map(ele => {
            return {
              bill_dtl_id: ele.bill_dtl_id ? ele.bill_dtl_id : 0,
              dtl_id: ele.dtl_id,
              bill_amount: ele.amount_to_be_billed,
              amount_not_to_be_billed: ele.amount_not_to_be_billed,
              remark: ele.remark,
              tax_category: ele.tax_cat_id,
              additional_amount: ele.additional_amount,
              percentage: ele.percentage
            };
          });

          // const itemDetails = [];
          // for (let i = 0; i < this.generateBillData.length; i++) {
          //   const itemA = this.initialGenerateBillData[i];
          //   const itemB = this.generateBillData[i];
          //   if (
          //     itemA.amount_to_be_billed != itemB.amount_to_be_billed ||
          //     itemA.amount_not_to_be_billed != itemB.amount_not_to_be_billed ||
          //     itemA.remark != itemB.remark ||
          //     itemA.tax != itemB.tax ||
          //     itemA.percentage != itemB.percentage
          //   ) {
          //     itemDetails.push({
          //       bill_dtl_id: itemB.bill_dtl_id ? itemB.bill_dtl_id : 0,
          //       dtl_id: itemB.dtl_id,
          //       bill_amount: itemB.amount_to_be_billed,
          //       amount_not_to_be_billed: itemB.amount_not_to_be_billed,
          //       remark: itemB.remark,
          //       tax_category: itemB.tax_cat_id,
          //       additional_amount: itemB.additional_amount,
          //       percentage: itemB.percentage
          //     });
          //   }
          // }
          const payload = {
            bill_hdr_id: this.billHdrId ? this.billHdrId : 0,
            cost_hdr_id: this.billRowData.cost_hdr_id,
            customer_id: this.customer.value,
            customer_site_id: this.customerSite.value,
            invoice_num: this.invNum,
            invoice_date: this.invDate,
            gl_date: this.glDate,
            tp_gstn: this.gstNum,
            cust_trx_type_id: this.trxType.value,
            org_id: this.billRowData.ou_id,
            type: this.trxType.code,
            fp_gstn: this.fpGst,
            user_id: this.$store.state.auth.userId,
            invdtl: payloadItemDtls ? payloadItemDtls : null
          };
          this.loader = true;
          this.$store
            .dispatch('fcBilling/updateGenerateBillData', payload)
            .then(resp => {
              this.loader = false;
              if (resp.status === 201) {
                this.editMode = false;
                this.billHdrId = resp.data.data.bill_hdr_id;
                this.billdtlIdsArr = resp.data.data.bill_dtl_id;
                this.$bvToast.toast(resp.data.message, {
                  title: 'Alert',
                  variant: 'success',
                  solid: true
                });
                this.getGenerateBillData();
                if (this.billRowData.formName === 'viewInvoice') {
                  this.$emit('getViewInvDtlsData');
                } else {
                  this.$emit('getFcBillingDtls');
                }
              } else {
                this.$bvToast.toast(resp.response.data.message, {
                  title: 'Alert',
                  variant: 'danger',
                  solid: true
                });
              }
            })
            .catch(() => {
              this.loader = false;
            });
        } else {
          this.$bvToast.toast('Please select invoices first.', {
            title: 'Alert',
            variant: 'danger',
            solid: true
          });
        }
      }
    },
    selectBoxChecked(flag, index, item, billHdrId) {
      this.generateBillData[index].selectBox = flag;
      if (flag === true) {
        this.selectedRow.push(item);
        this.selectedRecords = this.selectedRecords + 1;
      } else {
        this.selectedRow = this.selectedRow.filter(
          el => el.bill_hdr_id !== billHdrId
        );
        this.selectedRecords = this.selectedRecords - 1;
      }
    },
    selectAllBoxChecked(flag) {
      this.selectedRow = [];
      if (flag) {
        this.generateBillData = this.generateBillData.map((data, index) => {
          data.selectBox = this.selectAllCheckBox;
          if (this.selectAllCheckBox) {
            this.selectBoxChecked(data.selectBox, index);
          }
          return data;
        });
        this.selectedRow = this.generateBillData;
      } else {
        this.generateBillData = this.generateBillData.map((data, index) => {
          data.selectBox = this.selectAllCheckBox;
          if (this.selectAllCheckBox) {
            this.selectBoxChecked(data.selectBox, index);
          }
          return data;
        });
      }
    },
    defaultTaxCat() {
      this.generateBillData = this.generateBillData.map(ele => {
        ele.tax = this.taxCatgory.text;
        ele.tax_cat_id = this.taxCatgory.value;
        return ele;
      });
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
