// import appStrings from '@/app/utility/string.utility';
import commonHelper from '@/app/utility/common.helper.utility';
import DatePicker from 'vue2-datepicker';
import commonModalOracle from '../../provisionsAdmin/commonModalOracle';
import { required } from 'vuelidate/lib/validators';
import generateBill from './generateBill';
import viewInvoiceDtls from './viewInvoiceDtls';

export default {
  name: 'fcBilling',
  components: { DatePicker, commonModalOracle, generateBill, viewInvoiceDtls },
  watch: {
    currentPage: function() {
      this.getFcBillingDtls();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getFcBillingDtls();
    }
  },
  data() {
    return {
      loader: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1,
      defaultValue: {
        text: null,
        value: null
      },
      legalEntity: {
        text: null,
        value: null
      },
      costCenter: null,
      state: null,
      period: null,
      //   periodTo: {
      //     text: null,
      //     value: null
      //   },
      fcBillingData: [
        // { request_id: '3223' }
      ],
      fcBillingFields: [
        {
          key: 'le_id',
          label: 'Legal Entity',
          class: 'text-center'
        },
        {
          key: 'ou_name',
          label: 'OU Name',
          class: 'text-center'
        },
        {
          key: 'state',
          label: 'State',
          class: 'text-center'
        },
        {
          key: 'cost_center',
          label: 'Cost Center',
          class: 'text-center'
        },
        {
          key: 'fp_gst',
          label: 'FP GST',
          class: 'text-center'
        },
        {
          key: 'period_name',
          label: 'Period',
          class: 'text-center'
        },
        {
          key: 'generate_cost',
          label: 'Generate Cost',
          class: 'text-center'
        },
        {
          key: 'generate_bill',
          label: 'Generate Bill',
          class: 'text-center'
        },
        {
          key: 'view_invoice',
          label: 'View Invoice',
          class: 'text-center'
        }
      ],
      modalType: null,
      showCommonModal: false,
      showValueSetModal: false,
      stateCode: null,
      accessButtons: {
        deleteAllow: false,
        downloadAllow: false,
        uploadAllow: false,
        editAllow: true,
        addAllow: true,
        printAllow: false,
        approvalAllow: false
      },
      billRowData: null,
      showGenerateBillModal: false,
      legalEntityValue: null,
      showViewBillModal: false
    };
  },
  validations: {
    legalEntity: { text: { required } },
    period: { required }
  },
  mounted() {},
  methods: {
    getFcBillingDtls() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const payload = {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          le_id: this.legalEntity.value,
          state: this.state,
          cost_center: this.costCenter,
          period_name: this.period
        };
        this.loader = true;
        this.$store
          .dispatch('fcBilling/getFcBillingData', payload)
          .then(resp => {
            this.loader = false;
            if (resp.status === 200) {
              this.fcBillingData = resp.data.data.page;
              this.totalRows = resp.data.data.total_elements;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    generateCost(item, index) {
      const payload = {
        InputParameters: [
          {
            REQUEST_NAME: 'XXCCS_FC_BILL_DETAIL_PRC',
            OU_NAME: 'Delhivery -Transport',
            USER_NAME: 'AMRITANSH.RASTOGI',
            RESP_NAME: 'Payables',
            ARGUMENT1: item.ledger_id,
            ARGUMENT2: item.period_name,
            ARGUMENT3: item.cost_center,
            ARGUMENT4: item.state,
            ARGUMENT5: this.$store.state.auth.userId,
            ARGUMENT6: '',
            ARGUMENT7: '',
            ARGUMENT8: '',
            ARGUMENT9: '',
            ARGUMENT10: '',
            ARGUMENT11: '',
            ARGUMENT12: '',
            ARGUMENT13: '',
            ARGUMENT14: '',
            ARGUMENT15: ''
          }
        ]
      };
      this.loader = true;
      this.$store
        .dispatch('fcBilling/generateCost', payload)
        .then(resp => {
          setTimeout(() => {
            // this.getFcBillingDtls();
            this.loader = false;
          }, 4500);
          if (resp.status === 201) {
            setTimeout(() => {
              if (resp.data.data.OutputParameters[0].REQUEST_ID) {
                // item.disabledBtn = true;
                this.fcBillingData[index].disabledBtn = true;
              }
              this.$bvToast.toast(resp.data.message, {
                title: 'Alert',
                variant: 'success',
                solid: true
              });

              this.getFcBillingDtls();
            }, 5000);
          } else {
            this.$bvToast.toast(resp.response.data.message, {
              title: 'Alert',
              variant: 'danger',
              solid: true
            });
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    generateViewBill(item, btnType) {
      if (item.request_id) {
        this.billRowData = item;
        this.billRowData.le_id_code = this.legalEntity.value;
        if (btnType === 'genBillBtn') {
          this.billRowData.formName = 'gridForm';
          this.showHideGenerateBillModal(true);
        } else {
          if (item.bill_hdr_id) {
            this.showHideViewBillModal(true);
          } else {
            this.$bvToast.toast('Please generate bill first', {
              title: 'Alert',
              variant: 'danger',
              solid: true
            });
          }
        }
      } else {
        this.showCommonModal = false;
        this.$bvToast.toast('Please generate cost first', {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
      }
    },
    viewInvoiceDtls() {},
    showHideGenerateBillModal(flag) {
      this.showGenerateBillModal = flag;
    },
    showHideViewBillModal(flag) {
      this.showViewBillModal = flag;
    },
    showHideCommonModal(flag, type) {
      this.modalType = type;
      //   this.showCommonModal = flag;
      if (flag) {
        if (this.modalType === 'cost_center') {
          this.stateCode = this.state;
          this.legalEntityValue = this.legalEntity.value;
        }
      }
      if (this.modalType === 'cost_center' && !this.legalEntity.value) {
        this.showCommonModal = false;
        this.$bvToast.toast('Please Select Legal Entity.', {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
      } else if (this.modalType === 'cost_center' && !this.stateCode) {
        this.showCommonModal = false;
        this.$bvToast.toast('Please Select State.', {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
      } else {
        this.showCommonModal = flag;
      }
    },
    mapSelectedVsetData(item) {
      if (this.modalType === 'legal_entity') {
        this.legalEntity = {
          text: item.le_name,
          value: item.le_id
        };
      } else if (this.modalType === 'period') {
        this.period = item.period_name;
      } else if (this.modalType === 'state_code') {
        this.state = item.state_code;
      } else if (this.modalType === 'cost_center') {
        this.costCenter = item.cost_center;
      }
      this.showHideCommonModal(false);
    },
    clearVsetValues(vsetCode) {
      switch (vsetCode) {
        case 'legal_entity':
          this.legalEntity = this.defaultValue;
          break;
        case 'period':
          this.period = null;
          break;
        case 'costCenter':
          this.costCenter = null;
          break;
        case 'state':
          this.state = null;
          this.costCenter = null;
          this.stateCode = null;
          break;
      }
    },
    clear() {
      this.legalEntity = this.defaultValue;
      this.state = null;
      this.costCenter = null;
      this.stateCode = null;
      this.period = null;
      this.totalRows = null;
      this.fcBillingData = [];
    },
    getReqStatus(item, btnType) {
      if (item.request_id) {
        const payload = {
          request_id: item.request_id
        };
        this.loader = true;
        this.$store
          .dispatch('fcBilling/getReqStatus', payload)
          .then(resp => {
            this.loader = false;
            if (resp.status === 200) {
              const reqStatuData = resp.data.data.page[0];
              if (btnType === 'reqIdBtn') {
                this.showReqStatusMsg(reqStatuData);
              } else {
                if (
                  reqStatuData.phase === 'Completed' &&
                  reqStatuData.status === 'Normal'
                ) {
                  this.generateViewBill(item, btnType);
                }
              }
            }
          })
          .catch(() => {
            this.loader = false;
          });
      } else {
        this.$bvToast.toast('Please generate cost first', {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
      }
    },
    showReqStatusMsg(reqStatusMsg) {
      this.$bvModal.msgBoxOk(
        `The Request Status is ${reqStatusMsg.status} and phase is ${reqStatusMsg.phase}`,
        {
          title: 'Request Status',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'success',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true,
          contentClass: 'custom-modal-content'
        }
      );
    }
  }
};
